import { useState } from 'react';
import { useIsomorphicEffect } from './useIsomorphicEffect';

type WindowDimensions = {
  innerWidth: number | null;
  innerHeight: number | null;
  outerWidth: number | null;
  outerHeight: number | null;
};

const nullDimensions: WindowDimensions = {
  innerHeight: null,
  innerWidth: null,
  outerHeight: null,
  outerWidth: null,
};

function getDimensions() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export function useWindowSize(): WindowDimensions {
  const _a = useState(function () {
      if (typeof window !== 'undefined') {
        return getDimensions();
      } else {
        return nullDimensions;
      }
    }),
    windowSize = _a[0],
    setWindowSize = _a[1];

  useIsomorphicEffect(function () {
    function onResize() {
      setWindowSize(getDimensions());
    }
    window.addEventListener('resize', onResize);
    return function () {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return windowSize;
}
