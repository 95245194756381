export const knowledgeIcon = {
  body: `

<path d="M62.9749 50.681V45.1375H57.0618V41.4418H55.0907V38.362H57.0618V32.8185H42.323V34.6663H55.2139V36.5142H16.981V38.362H53.2428V41.4418H13.0213C12.1722 41.4418 11.4814 40.751 11.4814 39.9019C11.4814 39.0528 12.1722 38.362 13.0213 38.362H15.1331V36.5142H13.0213C11.1534 36.5142 9.63358 38.0338 9.63358 39.9019C9.63358 41.77 11.1534 43.2896 13.0213 43.2896H55.2138V45.1375H23.3692V46.9853H61.1269V48.8332H8.95604C6.06909 48.8332 3.72047 51.1818 3.72047 54.0687C3.72047 56.9557 6.06909 59.3043 8.95604 59.3043H52.4771V57.4565H8.95616C7.08811 57.4565 5.56844 55.9368 5.56844 54.0687C5.56844 52.2007 7.08811 50.681 8.95616 50.681H59.156V53.7608H18.1954V55.6086H59.156V57.4565H54.3251V59.3043H61.127V61.1522H8.95616C5.05031 61.1522 1.87275 57.9746 1.87275 54.0687C1.87275 50.1629 5.05031 46.9853 8.95616 46.9853H21.5215V45.1375H13.0214C10.1345 45.1375 7.78586 42.7889 7.78586 39.9019C7.78586 37.0149 10.1345 34.6663 13.0214 34.6663H40.4751V32.8185H13.0214C9.11557 32.8185 5.93801 35.996 5.93801 39.9019C5.93801 41.989 6.8458 43.8676 8.28675 45.1652C3.67378 45.509 0.0249023 49.3694 0.0249023 54.0687C0.0249023 58.9935 4.0314 63 8.95616 63H62.9749V57.4565H61.0038V50.681H62.9749Z" fill="currentColor"/>
<path d="M14.4995 53.7607H16.3474V55.6086H14.4995V53.7607Z" fill="currentColor"/>
<path d="M17.0249 27.4613L17.4363 27.7357C17.5967 27.8426 21.4799 30.3547 31.4997 30.3547C41.5194 30.3547 45.4026 27.8426 45.563 27.7357L45.9745 27.4613V11.9057L51.518 10.0578V18.7808C50.0979 19.1841 49.0542 20.4918 49.0542 22.0394V26.659H55.8296V22.0394C55.8296 20.4918 54.786 19.1841 53.3658 18.7808V9.44188L56.5955 8.36533L46.8923 5.13086L46.3079 6.88385L50.7521 8.3652L31.4997 14.7828L12.2472 8.36533L31.4997 1.94788L44.5549 6.29968L45.1394 4.54669L31.4997 0L6.40381 8.36533L17.0249 11.9057V27.4613ZM53.9818 22.0394V24.8112H50.9021V22.0394C50.9021 21.1902 51.5928 20.4995 52.4419 20.4995C53.2911 20.4995 53.9818 21.1902 53.9818 22.0394ZM18.8727 12.5216L31.4997 16.7307L44.1266 12.5216V19.7604H41.8476V21.6082H44.1266V23.4561H40.1229V25.3039H44.1266V26.4178C42.9359 26.9977 39.1048 28.5069 31.4997 28.5069C23.8888 28.5069 20.0575 26.9953 18.8727 26.4183V12.5216Z" fill="currentColor"/>
<path d="M10.8042 53.7607H12.652V55.6086H10.8042V53.7607Z" fill="currentColor"/>
<path d="M36.4277 23.4561H38.2756V25.3039H36.4277V23.4561Z" fill="currentColor"/>
</defs>
`,
  width: 63,
  height: 63,
};
