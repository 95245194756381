import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Hamburger from '../../atoms/Hamburger/Hamburger';
import Icon from '@iconify/react';
import { emailIcon, phoneIcon } from '../../../assets/icons';
import { NAVIGATION_ITEMS, PHONE_NUMBER, EMAIL } from '../../../config/config';
import gsap from 'gsap';
import SweetScroll from 'sweet-scroll';
import { Link } from 'gatsby';
import { useWindowSize, useWindowScroll, useEvent } from 'react-use';

const StyledWrapper = styled.nav``;

const StyledNav = styled.div`
  position: fixed;
  top: 40px;
  right: 130px;
  z-index: 99999;
  display: flex;

  @media (max-width: 1400px) {
    right: 75px;
  }

  @media (max-width: 1024px) {
    top: 0;
    right: 0;
    width: 100%;
    background: #fff;
    justify-content: flex-end;
    padding: 0 5%;
    border-bottom: 1px solid #bbbbbb;
  }
`;

const StyledNavItem = styled.div`
  width: 70px;
  height: 70px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border: 1px solid #fff;
  border-right: 0;
  :nth-child(2) {
    border-left: 0;
  }
  color: #000;

  ${StyledNav}.is-more-than-90vh & {
    @media (min-width: 1025px) {
      :nth-child(1),
      :nth-child(2) {
        border: 1px solid #bbbbbb;
        border-right: 0;
      }
      :nth-child(2) {
        border-left: 0;
      }
    }
  }

  svg {
    font-size: 2rem;
  }

  :not(:last-child):hover {
    background: #eaeaea;
  }

  :last-child {
    background: #000;
    border: 0;

    :hover {
      background: #212121;
    }
  }

  @media (max-width: 1024px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 390px) {
    width: 50px;
    height: 50px;
    svg {
      font-size: 1.6rem;
    }
  }
`;

const StyledListWrapper = styled.div`
  position: fixed;
  left: -30vw;
  top: 0;
  width: 30vw;
  height: 100%;
  z-index: 99999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 480px;

  &.is-more-than-90vh {
    border-right: 1px solid #bbbbbb;
  }

  @media (max-width: 1024px) {
    left: -100vw;
    width: 100%;
    top: 61px;
    border-right: none !important;
    height: calc(100% - 61px);
    min-width: unset;
  }

  @media (max-height: 690px) {
    z-index: 999999999;
  }

  @media (max-width: 390px) {
    top: 51px;
    height: calc(100% - 31px);
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  list-style-type: none;

  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
  }
`;

const StyledListItem = styled.li<{ $isActive?: boolean }>`
  text-align: right;
  font-size: 2.6rem;
  color: #ccc;
  margin-bottom: 35px;
  position: relative;
  opacity: 0;
  transition: color 0.4s ease-in-out, transform 0.2s ease-in-out;

  :last-of-type {
    margin-bottom: 0;
  }

  ::after {
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: #ccc;
    position: absolute;
    transform: translateY(5px) scaleX(0);
    transition: transform 0.4s ease-in-out, background 0.4s ease-in-out;
    transform-origin: right center;
  }

  :hover {
    transform: translateX(-5px);
    color: #8d8d8d;

    ::after {
      transform: translateY(5px) scaleX(1);
      background: #8d8d8d;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: #000;
      transform: translateX(-5px);

      ::after {
        transform: translateY(5px) scaleX(1);
        background: #000;
      }

      :hover {
        color: #000;

        ::after {
          background: #000;
        }
      }
    `}
  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const Navigation: FC<{ scroller: SweetScroll }> = ({ scroller }) => {
  const [isActiveNav, setActiveNav] = useState(false);
  const [offsets, setOffsets] = useState<number[]>();
  const [currentPlace, setCurrentPlace] = useState<number>();
  const list = useRef(null);
  const { y } = useWindowScroll();
  const { height, width } = useWindowSize();

  const checkOffsets = () => {
    const win = typeof window !== 'undefined' && window;
    const scrollTop =
      y || win.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop < offsets?.[1] - 200) {
      setCurrentPlace(0);
      return;
    }

    offsets?.slice(1).forEach((offset, index) => {
      if (scrollTop >= offset - 200) {
        setCurrentPlace(index + 1);
      }
    });
  };

  useEvent('scroll', checkOffsets, typeof window !== 'undefined' && window);

  const isAnotherThanIndex =
    typeof window !== 'undefined' && window.location.pathname.includes('lokal');

  useEffect(() => {
    if (isAnotherThanIndex) return;

    let offsets: number[] = [0];

    NAVIGATION_ITEMS.forEach(({ href }) => {
      const rect = document.querySelector(href).getBoundingClientRect();
      const win = typeof window !== 'undefined' && window;

      offsets = [
        ...offsets,
        rect.top + win.pageYOffset || document.documentElement.scrollTop,
      ];
    });

    setOffsets(offsets);

    return () => {
      setOffsets([]);
    };
  }, []);

  useEffect(() => {
    if (NAVIGATION_ITEMS.length === offsets?.length - 1) {
      checkOffsets();
    }
  }, [offsets]);

  useEffect(() => {
    const tl = gsap.timeline();

    if (isActiveNav) {
      tl.to(list.current, {
        duration: 1,
        x: width > 1024 ? '30vw' : '100vw',
        ease: 'power3.out',
      }).to((list.current as HTMLElement).querySelectorAll('li'), {
        ease: 'power3.out',
        opacity: 1,
        stagger: 0.1,
        delay: -0.5,
      });
    } else {
      tl.to((list.current as HTMLElement).querySelectorAll('li'), {
        ease: 'power3.out',
        opacity: 0,
        stagger: {
          each: 0.1,
          from: 'end',
        },
      }).to(list.current, {
        duration: 1,
        delay: -0.8,
        x: width > 1024 ? '-30vw' : '-100vw',
        ease: 'power3.in',
      });
    }
  }, [isActiveNav]);

  const isMoreThan90vh =
    y > height * 0.9 || isAnotherThanIndex ? 'is-more-than-90vh' : undefined;

  return (
    <StyledWrapper>
      <StyledNav className={isMoreThan90vh}>
        <StyledNavItem
          as="a"
          href={`tel:${PHONE_NUMBER.replace(/\s/g, '')}`}
          aria-label="Zadzwoń"
        >
          <Icon icon={phoneIcon} />
        </StyledNavItem>
        <StyledNavItem
          as="a"
          href={`mailto:${EMAIL}`}
          aria-label="Wyślij wiadomość e-mail"
        >
          <Icon icon={emailIcon} />
        </StyledNavItem>
        <StyledNavItem
          onClick={() => setActiveNav((prevState) => !prevState)}
          as="button"
          aria-label={isActiveNav ? 'Zamknij nawigację' : 'Otwórz nawigację'}
        >
          <Hamburger isActive={isActiveNav} />
        </StyledNavItem>
      </StyledNav>

      <StyledListWrapper ref={list} className={isMoreThan90vh}>
        <StyledList>
          {isAnotherThanIndex ? (
            <>
              <StyledListItem>
                <Link to="/">Start</Link>
              </StyledListItem>
              {NAVIGATION_ITEMS.map(({ name, href }) => (
                <StyledListItem key={href}>
                  <Link to="/" state={{ scrollTo: href }}>
                    {name}
                  </Link>
                </StyledListItem>
              ))}
            </>
          ) : (
            <>
              <StyledListItem $isActive={currentPlace === 0}>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => setActiveNav(false), 500);
                    scroller.to(0);
                  }}
                  rel="noopener"
                >
                  Start
                </a>
              </StyledListItem>
              {NAVIGATION_ITEMS.map(({ name, href }, index) => (
                <StyledListItem
                  key={href}
                  $isActive={currentPlace === index + 1}
                >
                  <a
                    rel="noopener"
                    href={href}
                    data-scroll=""
                    onClick={() => setTimeout(() => setActiveNav(false), 500)}
                  >
                    {name}
                  </a>
                </StyledListItem>
              ))}
            </>
          )}
        </StyledList>
      </StyledListWrapper>
    </StyledWrapper>
  );
};

export default Navigation;
