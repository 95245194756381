import React from 'react';
import styled, { keyframes } from 'styled-components';
import icon from '@iconify/icons-lucide/loader-2';
import { Icon } from '@iconify/react';

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999999999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  &.loader-exit,
  &.loader-exit-done {
    opacity: 0;
    pointer-events: none;
  }
`;

const animation = keyframes`
  0% {
    transform: rotate(0);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  color: #1f1f1f;
  animation: ${animation} 0.7s linear infinite;
`;

const Loader = () => (
  <StyledWrapper>
    <StyledIcon icon={icon} />
  </StyledWrapper>
);

export default Loader;
