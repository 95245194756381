import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../theme/GlobalStyles';
import Navigation from '../components/molecules/Navigation/Navigation';
import SweetScroll from 'sweet-scroll';
import { CSSTransition } from 'react-transition-group';
import CookieNotice from '@rendpro/react-cookie-notice';
import Loader from '../components/atoms/Loader/Loader';
import { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const BasicTemplate: FC<{
  header?: React.FunctionComponent;
  scrollTo?: string;
  isAnotherThanIndex?: boolean;
  title?: string;
}> = ({ children, header: Header, scrollTo, title, isAnotherThanIndex }) => {
  const [scroller, setScroller] = useState<SweetScroll>();
  const [isLoaded, setLoaded] = useState<boolean>(isAnotherThanIndex);
  const {
    datoCmsSetting,
    file: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src: ogImage },
          },
        },
      },
    },
  }: { datoCmsSetting: { title: string; description: string }; file: any } =
    useStaticQuery(query);

  useEffect(() => {
    setScroller(
      new SweetScroll({
        duration: 2000,
        offset: -100,
      })
    );
  }, []);

  useEffect(() => {
    scrollTo && scroller?.to(scrollTo, { duration: 3000 });
    typeof window !== 'undefined' && window.history.replaceState(null, '');
  }, [scrollTo, scroller]);

  useEffect(() => {
    const win = typeof window !== 'undefined' && window;

    const setVh = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${win.innerHeight * 0.01}px`
      );
    };

    setVh();
    setTimeout(() => setLoaded(true), 300);

    win?.addEventListener('resize', setVh);

    return () => {
      win?.removeEventListener('resize', setVh);
    };
  }, []);

  const readyTitle = title
    ? `${title} | ${datoCmsSetting.title}`
    : datoCmsSetting.title;

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>{readyTitle}</title>
        <meta name="description" content={datoCmsSetting.description} />
        <html lang="pl" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content={readyTitle} />
        <meta property="og:description" content={datoCmsSetting.description} />
      </Helmet>
      <header>
        <Navigation scroller={scroller} />
        {Header && <Header />}
      </header>
      {children}
      <CSSTransition
        in={!isLoaded}
        timeout={500}
        classNames="loader"
        unmountOnExit
      >
        <Loader />
      </CSSTransition>
      <CookieNotice
        text="Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy."
        buttonText="Akceptuję"
        links={[
          {
            name: 'Polityka prywatności',
            link: 'https://jakwylaczyccookie.pl/polityka-cookie/',
          },
          {
            name: 'Jak wyłączyć cookie',
            link: 'https://jakwylaczyccookie.pl/jak-wylaczyc-pliki-cookies/',
          },
          {
            name: 'Cyberbezpieczeństwo',
            link: 'https://nety.pl/cyberbezpieczenstwo',
          },
        ]}
        customStyles={css`
          & {
            z-index: 214748363;
            box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.2);
          }
          button {
            font-family: 'Montserrat', sans-serif !important;
            border-radius: 0;
          }
        `}
        foregroundColor="#1f1f1f"
        backgroundColor="#fff"
        linksColor="#1f1f1f"
        buttonBackgroundColor="#1f1f1f"
        buttonColor="#fff"
      />
    </>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      title
      description
    }
    file(name: { eq: "hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, formats: JPG, layout: FIXED, width: 1200)
      }
    }
  }
`;

export default BasicTemplate;
