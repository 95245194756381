import { css } from 'styled-components';
import { keyframes } from 'styled-components';

export const maxWidth = css`
  width: 90%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
`;

export const decorationLine = css`
  padding-left: 30px;
  position: relative;

  ::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: #000;
  }

  @media (max-width: 1024px) {
    padding-left: 0;

    ::before {
      display: none;
    }
  }
`;

export const rippleAnimation = keyframes`
  0% {
    opacity: 0.6;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
