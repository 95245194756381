import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import { angleRightIcon } from '../../../assets/icons';
import { useButtonEffects } from '../../../hooks/useButtonEffects';
import { rippleAnimation } from '../../../theme/repeatedStyles';

const StyledButton = styled.button`
  padding: 12px 25px;
  border: 1px solid #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.4s ease-in-out;
  transform-origin: center center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  color: #000;

  svg:first-of-type {
    margin-right: 5px;
  }

  svg:last-of-type {
    margin-left: 5px;
    transform: rotate(180deg) !important;
  }

  :hover {
    background: #000;
    color: #fff;
  }

  :active {
    transform: scale(0.95);
  }

  .ripple {
    width: 120px;
    height: 120px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }
`;

const Button: FC<{
  className?: string;
  to?: string;
  as?: any;
  target?: string;
  href?: string;
}> = ({ children, className, as, target, href, ...props }) => {
  const { onMouseDown } = useButtonEffects();

  return (
    <StyledButton
      className={className}
      onMouseDown={onMouseDown}
      as={as}
      target={target}
      href={href}
      {...props}
    >
      <Icon icon={angleRightIcon} />
      {children}
      <Icon icon={angleRightIcon} />
    </StyledButton>
  );
};

export default Button;
