import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.div<{ $isActive: boolean }>`
  width: 25px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background: #fff;
    transition: transform 0.5s ease-in-out;
    transform: scaleX(100%);

    :nth-of-type(1) {
      transform-origin: center center;
    }

    :nth-of-type(3) {
      transform-origin: center center;
      top: 100%;
      left: 0;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      span {
        :nth-of-type(2) {
          transform: scaleX(0%);
        }

        :nth-of-type(1) {
          transform: translate(0, 400%) rotate(45deg);
        }

        :nth-of-type(3) {
          transform: translate(0, -400%) rotate(-45deg);
        }
      }
    `};

  @media (max-width: 390px) {
    width: 20px;
    height: 14px;

    ${({ $isActive }) =>
      $isActive &&
      css`
        span {
          :nth-of-type(2) {
            transform: scaleX(0%);
          }

          :nth-of-type(1) {
            transform: translate(0, 300%) rotate(45deg);
          }

          :nth-of-type(3) {
            transform: translate(0, -300%) rotate(-45deg);
          }
        }
      `};
  }
`;

const Hamburger: FC<{
  isActive: boolean;
  onChange?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isActive, onChange }) => {
  return (
    <StyledWrapper
      onClick={onChange ? () => onChange((prevState) => !prevState) : undefined}
      $isActive={isActive}
    >
      <span />
      <span />
      <span />
    </StyledWrapper>
  );
};

export default Hamburger;
