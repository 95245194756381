import * as React from "react";
import styled from "styled-components";
import logo from '../../../assets/images/logo.svg'

const Logo = ({...props}) => {
    return (
        <img src={logo} alt="Nowa Farma"  {...props} />
    )
}

export default Logo;