import { createGlobalStyle } from 'styled-components';
import normalize from 'styled-normalize';

export default createGlobalStyle`
  ${normalize}
  
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  
  html {
    font-size: 10px;
  }
  
  body {
    font-size: 1.6rem;
  }
  
  main {
    max-width: 100%;
    overflow: hidden;
  }
  
  p {
    line-height: 1.5;
  }
  
  .lightbox-portal {
    z-index: 214748363!important;
  }
`;
